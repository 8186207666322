export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'statistics',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'employeesDashboard',
        route: 'employeesDashboard',
        permission: 'viewEmployeesDashboard',
        isFav: false,
      },
    ],
  },
  {
    title: 'publicCodes',
    icon: 'LayersIcon',
    children: [
      {
        title: 'income',
        route: 'income',
        permission: 'viewIncomes',
        isFav: false,
      },
      {
        title: 'expense',
        route: 'expense',
        permission: 'viewExpenses',
        isFav: false,
      },
      {
        title: 'discountTypes',
        route: 'discounts',
        permission: 'viewDiscounts',
        isFav: false,
      },
      {
        title: 'banks',
        route: 'banks',
        permission: 'viewBanks',
        isFav: false,
      },
      {
        title: 'PaymentMethods',
        route: 'payment-methods',
        permission: 'viewPaymentMethods',
        isFav: false,
      },
      {
        title: 'safes',
        route: 'safes',
        permission: 'viewSafes',
        isFav: false,
      },
      {
        title: 'suppliers',
        route: 'suppliers',
        permission: 'viewSuppliers',
        isFav: false,
      },
      {
        title: 'areas',
        route: 'areas',
        permission: 'viewAreas',
        isFav: false,
      },
      {
        title: 'drivers',
        route: 'drivers',
        permission: 'viewDrivers',
        isFav: false,
      },
      {
        title: 'buses',
        route: 'buses',
        permission: 'viewBuses',
        isFav: false,
      },
    ],
  },
  {
    title: 'schoolData',
    icon: 'ServerIcon',
    children: [
      {
        title: 'nationalties',
        route: 'nationalties',
        permission: 'viewNationalities',
        isFav: false,
      },
      {
        title: 'studentStatuses',
        route: 'studentStatuses',
        permission: 'viewStudentStatuses',
        isFav: false,
      },
      {
        title: 'serviceCategories',
        route: 'serviceCategories',
        permission: 'viewServiceCategories',
        isFav: false,
      },
      {
        title: 'services',
        route: 'services',
        permission: 'viewServices',
        isFav: false,
      },
      {
        title: 'stages',
        route: 'stages',
        permission: 'viewStages',
        isFav: false,
      },
      {
        title: 'rows',
        route: 'rows',
        permission: 'viewRows',
        isFav: false,
      },
      {
        title: 'classRooms',
        route: 'classRooms',
        permission: 'viewClasses',
        isFav: false,
      },
      {
        title: 'guardians-list',
        route: 'guardians-list',
        permission: 'viewGuardians',
        isFav: false,
      },
      {
        title: 'students-list',
        route: 'students-list',
        permission: 'viewStudents',
        isFav: false,
      }
    ],
  },
  {
    title: 'items',
    icon: 'ShoppingBagIcon',
    isEnabledStore: true,
    children: [
      {
        title: 'units',
        route: 'units',
        permission: 'viewUnits',
        isFav: false,
        isEnabledStore: true,
      },
      {
        title: 'itemsCategories',
        route: 'items-category',
        permission: 'viewItemCategories',
        isFav: false,
        isEnabledStore: true,
      },
      {
        title: 'itemsList',
        route: 'items',
        permission: 'viewItems',
        isFav: false,
        isEnabledStore: true,
      },
    ],
  },
  {
    title: 'humanResources',
    icon: 'PocketIcon',
    children: [
      {
        title: 'employees',
        route: 'employees-list',
        permission: 'viewEmployees',
        isFav: false,
      },
      {
        title: 'maritalStatus',
        route: 'marital-status',
        permission: 'viewMaritalStatus',
        isFav: false,
      },
      {
        title: 'salaryItems',
        route: 'salary-items',
        permission: 'viewBenefitAndDeduction',
        isFav: false,
      },
      {
        title: 'qualification',
        route: 'qualification',
        permission: 'viewQualification',
        isFav: false,
      },
      {
        title: 'sections',
        route: 'sections',
        permission: 'viewSections',
        isFav: false,
      },
      {
        title: 'jopTitle',
        route: 'jop-title',
        permission: 'viewJopTitle',
        isFav: false,
      },
      {
        title: 'mangement',
        route: 'mangement',
        permission: 'viewMangement',
        isFav: false,
      },
      {
        title: 'religion',
        route: 'religion',
        permission: 'viewReligion',
        isFav: false,
      },
      {
        title: 'typeVacations',
        route: 'typeVacations',
        permission: 'viewTypesVacation',
        isFav: false,
      },
    ],
  },
  {
    title: 'employeeMovement',
    icon: 'FigmaIcon',
    children: [
      {
        title: 'vacationRequest',
        route: 'vacationRequest',
        permission: 'viewVacationRequest',
        isFav: false,
      },
      {
        title: 'benefitsAndDeductions',
        route: 'benefitsAndDeductions',
        permission: 'viewBenefitAndDeduction',
        isFav: false,
      },
      {
        title: 'advancedPayments',
        route: 'employeeAdvancePayment',
        permission: 'viewAdvancePayment',
        isFav: false,
      },
      {
        title: 'calculateSalary',
        route: 'calculateSalary',
        permission: 'viewCalculateSalary',
        isFav: false,
      },
      {
        title: 'payrollManager',
        route: 'salary',
        permission: 'viewCalculateSalary',
        isFav: false,
      }
    ]
  },
  {
    title: 'invoices',
    icon: 'ArchiveIcon',
    children: [
      // {
      //   title: 'invoices',
      //   route: 'invoices',
      //   permission: '',
      // },
      {
        title: 'invoiceList',
        route: 'invoices',
        permission: 'viewSalesTransactions',
        isFav: false,
      },
      {
        title: 'salesPoints',
        route: 'pos-invoice',
        permission: 'addSalesTransactions',
        isFav: false,
      },
      {
        title: 'returnSales',
        route: 'return-invoice',
        permission: 'addReturnSalesTransactions',
        isFav: false,
      },
      {
        title: 'deservedFees',
        route: 'deservedFees',
        permission: 'viewDeservedFees',
        isFav: false,
      }
    ],
  },
  {
    title: 'purchases',
    icon: 'ShoppingCartIcon',
    isEnabledStore: true,
    children: [
      {
        title: 'previewPurchases',
        route: 'purchInvoice-list',
        permission: 'viewPurchTransactions',
        isFav: false,
        isEnabledStore: true,
      },
      // {
      //   title: 'purchOrder',
      //   route: 'purchOrder',
      //   permission: 'addPurchOrders',
      //   isFav: false,
      // },
      {
        title: 'purch',
        route: 'purchInvoice',
        permission: 'addPurchTransactions',
        isFav: false,
        isEnabledStore: true,
      },
      // {
      //   title: 'returnPurchases',
      //   route: 'return-purch-new',
      //   permission: 'addReturnPurchTransactions',
      //   isFav: false,
      // }
      {
        title: 'warehouses',
        route: 'warehouses',
        permission: 'viewStores',
        isFav: false,
        isEnabledStore: true,
      },
    ],
  },
  {
    title: 'stores',
    icon: 'TriangleIcon',
    isEnabledStore: true,
    children: [
      {
        title: 'orders',
        route: 'orders',
        permission: 'viewStockCategoryTransactions',
        isFav: false,
        isEnabledStore: true,
      },
      {
        title: 'exchangeOrder',
        route: 'exchange-order-new',
        permission: 'viewExchangeOrders',
        isFav: false,
        isForGeneral: true,
        isEnabledStore: true,
      },
      {
        title: 'additionOrder',
        route: 'addition-order-new',
        permission: 'viewAdditionOrders',
        isFav: false,
        isEnabledStore: true,
      },
      {
        title: 'storageTransfer',
        route: 'storage-transfer',
        permission: 'viewStorageTransfer',
        isFav: false,
        isEnabledStore: true,
      },
      {
        title: 'damageAdjustment',
        route: 'damage-adjustment',
        permission: 'viewDamageAdjustments',
        isFav: false,
        isEnabledStore: true,
      },
    ],
  },
  {
    title: 'inventory-wharehous',
    icon: 'BoxIcon',
    isEnabledStore: true,
    children: [
      {
        title: 'inventory',
        route: 'inventory',
        permission: 'viewStocktaking',
        isFav: false,
        isEnabledStore: true,
      },
      {
        title: 'shortageAdjustment',
        route: 'shortage-adjustment',
        permission: 'viewShortageAdjustment',
        isFav: false,
        isEnabledStore: true,
      },
      {
        title: 'overflowAdjustment',
        route: 'overflow-adjustment',
        permission: 'viewOverflowAdjustment',
        isFav: false,
        isEnabledStore: true,
      },
    ],
  },
  {
    title: 'assets',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Collection Transaction',
        route: 'collection-transactions',
        permission: 'viewCollectionTransactions',
        isFav: false,
      },
      {
        title: 'paymentTransaction',
        route: 'payment-transactions',
        permission: 'viewPaymentTransactions',
        isFav: false,
      },
      {
        title: 'studentDiscounts',
        route: 'studentDiscounts',
        permission: 'viewStudentDiscounts',
        isFav: false,
      },
      {
        title: 'expenseTransaction',
        route: 'expense-transactions',
        permission: 'viewExpenseTransactions',
        isFav: false,
      },
      {
        title: 'Income Transaction',
        route: 'income-transactions',
        permission: 'viewIncomeTransactions',
        isFav: false,
      },
      // {
      //   title: 'bankDeposit',
      //   route: 'bankDeposit-transactions',
      //   permission: 'bankDeposit',
      //   // isFav: false,
      // }
    ],
  },
  {
    title: 'accounts',
    icon: 'TrelloIcon',
    isEnabled: true,
    children: [
      {
        title: 'chartOfAccount',
        route: 'chartOfAccount',
        permission: 'viewAccounts',
        isFav: false,
        isEnabled: true,
      },
      {
        title: 'costCenter',
        route: 'costCenter',
        permission: 'viewCostCenters',
        isFav: false,
        isEnabled: true,
      },
      {
        title: 'vouchers',
        route: 'vouchers',
        permission: 'viewVouchers',
        isFav: false,
        isEnabled: true,
      },
      {
        title: 'postVouchers',
        route: 'post-vouchers',
        permission: 'gLPost',
        isFav: false,
        isEnabled: true,
      },
    ],
  },

  {
    title: 'procedures',
    icon: 'SlidersIcon',
    children: [
      {
        title: 'messages',
        route: 'messages',
        permission: 'viewMessages',
        isFav: false,
      },
      {
        title: 'discountsCollection',
        route: 'discountsCollection',
        permission: 'addDiscountCollection',
        isFav: false,
      },
      {
        title: 'studentInvoices',
        route: 'studentInvoices',
        permission: 'addStudentInvoices',
        isFav: false,
      },
      {
        title: 'deservedFeesCollection',
        route: 'deservedFeesCollection',
        permission: 'addDeservedFees',
        isFav: false,
      },
      {
        title: 'busAccommodation',
        route: 'busAccommodation',
        permission: 'addBusAccommodation',
        isFav: false,
      },
      {
        title: 'studentSuccess',
        route: 'studentSuccess',
        permission: 'createStudentSuccess',
        isFav: false,
      },
      {
        title: 'temporaryStudents',
        route: 'temporaryStudents',
        permission: 'viewRegistrationRequests',
        isFav: false,
      }
    ],
  },

  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'school-setting',
        route: 'school-setting',
        permission: 'viewSchool',
        isFav: false,
      },
      // {
      //   title: 'teamWork',
      //   route: 'accessGroups-list',
      //   // isFav: false,
      // },
      {
        title: 'Branches',
        route: 'branches-list',
        permission: 'viewBranches',
        isFav: false,
      },
      {
        title: 'users',
        route: 'users',
        permission: 'viewUsers',
        isFav: false,
      },
      {
        title: 'messages-settings',
        route: 'messages-settings',
        permission: 'addMessageSettings',
        isFav: false,
      },
    ],
  },
];
