export default [
    {
      path: '/vacationRequest',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'vacationRequest',
          component: () => import('@/pages/EmployeeMovement/VacationRequest/List.vue'),
          meta: {
            pageTitle: 'vacationRequest',
            breadcrumb: [{ text: 'employeeMovement', active: true }],
          },
        },
        {
          path: 'new',
          name: 'newVacationRequest',
          component: () => import('@/pages/EmployeeMovement/VacationRequest/Edit.vue'),
          meta: {
            pageTitle: 'newVacationRequest',
            breadcrumb: [
              { text: 'employeeMovement', active: true },
              { text: 'vacationRequest', active: true }
            ],
          },
        },
        {
          path: 'edit/:id',
          name: 'editVacationRequest',
          props: true,
          component: () => import('@/pages/EmployeeMovement/VacationRequest/Edit.vue'),
          meta: {
            pageTitle: 'editVacationRequest',
            breadcrumb: [
              { text: 'employeeMovement', active: true },
              { text: 'vacationRequest', active: true }
            ],
          },
        },
      ]
      // meta: {
      //   pageTitle: 'vacationRequest',
      //   breadcrumb: [{ text: 'employeeMovement', active: true }],
      // },
    },
    {
      path: '/benefitsAndDeductions',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'benefitsAndDeductions',
          component: () => import('@/pages/EmployeeMovement/BenefitsAndDeductions/List.vue'),
          meta: {
            pageTitle: 'benefitsAndDeductions',
            breadcrumb: [{ text: 'employeeMovement', active: true }],
          },
          permission: 'viewBenefitAndDeduction',
        },
        {
          path: 'new',
          name: 'benefitsAndDeductionsNew',
          component: () => import('@/pages/EmployeeMovement/BenefitsAndDeductions/Edit.vue'),
          meta: {
            pageTitle: 'benefitsAndDeductionsNew',
            breadcrumb: [
              { text: 'employeeMovement', active: true },
              { text: 'benefitsAndDeductions', active: true }
            ],
          },
        },
        {
          path: 'edit/:id',
          name: 'benefitsAndDeductionsEdit',
          props: true,
          component: () => import('@/pages/EmployeeMovement/BenefitsAndDeductions/Edit.vue'),
          meta: {
            pageTitle: 'employee-edit',
            breadcrumb: [
              { text: 'employeeMovement', active: true },
              { text: 'benefitsAndDeductions', active: true }
            ],
          },
        },
      ],
    },
    {
      path: '/employeeAdvancePayment',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'employeeAdvancePayment',
          component: () => import('@/pages/EmployeeMovement/AdvancePayment/List.vue'),
          meta: {
            pageTitle: 'advancedPayments',
            breadcrumb: [{ text: 'employeeMovement', active: true }],
          },
        },
        {
          path: 'new',
          name: 'employeeAdvancePayment-new',
          component: () => import('@/pages/EmployeeMovement/AdvancePayment/Edit.vue'),
          meta: {
            pageTitle: 'addEmployeeAdvancePayment',
            breadcrumb: [
              { text: 'employeeMovement', active: true },
              { text: 'advancedPayments', active: true }
            ],
          },
        },
        {
          path: 'edit/:id',
          name: 'employeeAdvancePayment-edit',
          props: true,
          component: () => import('@/pages/EmployeeMovement/AdvancePayment/Edit.vue'),
          meta: {
            pageTitle: 'editEmployeeAdvancePayment',
            breadcrumb: [
              { text: 'employeeMovement', active: true },
              { text: 'advancedPayments', active: true }
            ],
          },
        },
      ],
    },
    {
      path: '/calculateSalary',
      name: 'calculateSalary',
      component: () => import('@/pages/EmployeeMovement/CaculateSalary/CalculateSalary.vue'),
      meta: {
        pageTitle: 'calculateSalary',
        breadcrumb: [
          {
            text: 'employeeMovement',
            active: true,
          },
        ],
        permission: 'viewCalculateSalary',
      },
    },
    {
      path: '/salary',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
      {
        path: '',
        name: 'salary',
        component: () => import('@/pages/EmployeeMovement/Payroll/List.vue'),
      meta: {
        pageTitle: 'calculateSalary',
        breadcrumb: [
          {
            text: 'employeeMovement',
            active: true,
          },
        ],
        permission: 'viewCalculateSalary',
      },
    },
    {
      path: 'edit/:id',
      name: 'salaryEdit',
      props: true,
      component: () => import('@/pages/EmployeeMovement/Payroll/Edit.vue'),
      meta: {
        pageTitle: 'salary',
        breadcrumb: [
          { text: 'employeeMovement', active: true },
          { text: 'salary', active: true }
        ],
      },
    },
  ]
  },
  ];
