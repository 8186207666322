export default [
    {
      path: '/nationalties',
      name: 'nationalties',
      component: () => import('@/pages/SchoolData/Nationalties/List.vue'),
      meta: {
        pageTitle: 'nationalties',
        breadcrumb: [{ text: 'schoolData', active: true }],
      },
    },
    {
      path: '/studentStatuses',
      name: 'studentStatuses',
      component: () => import('@/pages/SchoolData/StudentStatuses/List.vue'),
      meta: {
        pageTitle: 'studentStatuses',
        breadcrumb: [{ text: 'schoolData', active: true }],
      },
    },
    // {
    //   path: '/learing-policy',
    //   name: 'learningPolicy',
    //   component: () => import('@/pages/SchoolData/LearningPolicy/List.vue'),
    //   meta: {
    //     pageTitle: 'learningPolicy',
    //     breadcrumb: [{ text: 'schoolData', active: true }],
    //   },
    // },
    {
      path: '/stages',
      name: 'stages',
      component: () => import('@/pages/SchoolData/Stages/List.vue'),
      meta: {
        pageTitle: 'stages',
        breadcrumb: [{ text: 'schoolData', active: true }],
      },
    },
    {
      path: '/classRooms',
      name: 'classRooms',
      component: () => import('@/pages/SchoolData/ClassRooms/List.vue'),
      meta: {
        pageTitle: 'classRooms',
        breadcrumb: [{ text: 'schoolData', active: true }],
      },
    },
    {
      path: '/rows',
      name: 'rows',
      component: () => import('@/pages/SchoolData/Rows/List.vue'),
      meta: {
        pageTitle: 'rows',
        breadcrumb: [{ text: 'schoolData', active: true }],
      },
    },

    {
      path: '/guardians',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'guardians-list',
          component: () => import('@/pages/SchoolData/Guardians/List.vue'),
          meta: {
            pageTitle: 'guardians-list',
            breadcrumb: [{ text: 'schoolData', active: true }],
          },
        },
        {
          path: 'new',
          name: 'guardian-new',
          component: () => import('@/pages/SchoolData/Guardians/Edit.vue'),
          meta: {
            pageTitle: 'guardian-new',
            breadcrumb: [{ text: 'guardians-list', active: true }],
            Permissions: 'addGuardians'
          },
        },
        {
          path: 'edit/:id',
          name: 'guardian-edit',
          props: true,
          component: () => import('@/pages/SchoolData/Guardians/Edit.vue'),
          meta: {
            pageTitle: 'guardian-edit',
            breadcrumb: [{ text: 'guardians-list', active: true }],
            Permissions: 'editGuardians'
          },
        },
      ],
    },

    {
      path: '/students',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'students-list',
          component: () => import('@/pages/SchoolData/Students/List.vue'),
          meta: {
            pageTitle: 'students-list',
            breadcrumb: [{ text: 'schoolData', active: true }],
          },
        },
        {
          path: 'new',
          name: 'student-new',
          component: () => import('@/pages/SchoolData/Students/Edit.vue'),
          meta: {
            pageTitle: 'student-new',
            breadcrumb: [{ text: 'students-list', active: true }],
            Permissions: 'addStudents'
          },
        },
        {
          path: 'edit/:id',
          name: 'student-edit',
          props: true,
          component: () => import('@/pages/SchoolData/Students/Edit.vue'),
          meta: {
            pageTitle: 'student-edit',
            breadcrumb: [{ text: 'students-list', active: true }],
            Permissions: 'editStudents'
          },
        },
      ],
    },
  ];
