export default
[
  {
    path: '/inventory',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'inventory',
        component: () => import('@/pages/Stores/Inventory/List.vue'),
        meta: {
          pageTitle: 'inventory',
          breadcrumb: [{ text: 'inventory-wharehous', active: true }],
          permission: 'viewStocktaking',
        },
      },
      {
        path: 'new',
        name: 'inventory-new',
        component: () => import('@/pages/Stores/Inventory/Edit.vue'),
        meta: {
          pageTitle: 'inventoryNew',
          breadcrumb: [
            { text: 'inventory-wharehous', active: true },
            { text: 'inventory', active: true },
          ],
          permission: 'addStocktaking',
        },
      },
      {
        path: 'edit/:id',
        name: 'inventory-edit',
        props: true,
        component: () => import('@/pages/Stores/Inventory/Edit.vue'),
        meta: {
          pageTitle: 'inventoryEdit',
          breadcrumb: [
            { text: 'inventory-wharehous', active: true },
            { text: 'inventory', active: true },
          ],
          permission: 'editStocktaking',
        },
      },
    ],
  },
  {
    path: '/shortage-adjustment',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'shortage-adjustment',
        component: () => import('@/pages/Stores/shortageAdjustment/List.vue'),
        meta: {
          pageTitle: 'shortageAdjustment',
          breadcrumb: [{ text: 'inventory-wharehous', active: true }],
          permission: 'viewShortageAdjustment',
        },
      },
      {
        path: 'new',
        name: 'shortage-adjustment-new',
        component: () => import('@/pages/Stores/shortageAdjustment/Edit.vue'),
        meta: {
          pageTitle: 'shortageAdjustmentNew',
          breadcrumb: [
            { text: 'inventory-wharehous', active: true },
            { text: 'shortageAdjustment', active: true },
          ],
          permission: 'addShortageAdjustment',
        },
      },
      {
        path: 'edit/:id',
        name: 'shortage-adjustment-edit',
        props: true,
        component: () => import('@/pages/Stores/shortageAdjustment/Edit.vue'),
        meta: {
          pageTitle: 'shortageAdjustmentEdit',
          breadcrumb: [
            { text: 'inventory-wharehous', active: true },
            { text: 'shortageAdjustment', active: true },
          ],
          permission: 'editShortageAdjustment',
        },
      },
    ],
  },
  {
    path: '/overflow-adjustment',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'overflow-adjustment',
        component: () => import('@/pages/Stores/shortageAdjustment/List.vue'),
        meta: {
          pageTitle: 'overflowAdjustment',
          breadcrumb: [{ text: 'inventory-wharehous', active: true }],
          permission: 'viewOverflowAdjustment',
        },
      },
      {
        path: 'new',
        name: 'overflow-adjustment-new',
        component: () => import('@/pages/Stores/shortageAdjustment/Edit.vue'),
        meta: {
          pageTitle: 'overflowAdjustmentNew',
          breadcrumb: [
            { text: 'inventory-wharehous', active: true },
            { text: 'overflowAdjustment', active: true },
          ],
          permission: 'addOverflowAdjustment',
        },
      },
      {
        path: 'edit/:id',
        name: 'overflow-adjustment-edit',
        props: true,
        component: () => import('@/pages/Stores/shortageAdjustment/Edit.vue'),
        meta: {
          pageTitle: 'overflowAdjustmentEdit',
          breadcrumb: [
            { text: 'inventory-wharehous', active: true },
            { text: 'overflowAdjustment', active: true },
          ],
          permission: 'editOverflowAdjustment',
        },
      },
    ],
  },

  {
    path: '/orders',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'orders',
        component: () => import('@/pages/Stores/orders/List.vue'),
        meta: {
          pageTitle: 'orders',
          breadcrumb: [{ text: 'stores', active: true }],
          permission: 'viewStockCategoryTransactions',
        },
      },
    ],
  },
  {
    path: '/exchange-order',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: 'new',
        name: 'exchange-order-new',
        component: () => import('@/pages/Stores/exchangeOrder/Edit.vue'),
        meta: {
          pageTitle: 'newExchangeOrder',
          breadcrumb: [{ text: 'stores', active: true }, { text: 'exchangeOrder', active: true }],
          permission: 'viewExchangeOrders',
        },
      },
      {
        path: 'edit/:id',
        name: 'exchange-order-edit',
        props: true,
        component: () => import('@/pages/Stores/exchangeOrder/Edit.vue'),
        meta: {
          pageTitle: 'editExchangeOrder',
          breadcrumb: [{ text: 'stores', active: true }, { text: 'exchangeOrder', active: true }],
          permission: 'editExchangeOrders',
        },
      },
    ],
  },
  {
    path: '/damage-adjustment',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'damage-adjustment',
        component: () => import('@/pages/Stores/damgeAdjustment/List.vue'),
        meta: {
          pageTitle: 'damageAdjustment',
          breadcrumb: [{ text: 'stores', active: true }],
          permission: 'viewDamageAdjustments',
        },
      },
      {
        path: 'new',
        name: 'damage-adjustment-new',
        component: () => import('@/pages/Stores/shortageAdjustment/Edit.vue'),
        meta: {
          pageTitle: 'damageAdjustmentNew',
          breadcrumb: [
            { text: 'stores', active: true },
            { text: 'damageAdjustment', active: true },
          ],
          permission: 'addDamageAdjustments',
        },
      },
      {
        path: 'edit/:id',
        name: 'damage-adjustment-edit',
        props: true,
        component: () => import('@/pages/Stores/shortageAdjustment/Edit.vue'),
        meta: {
          pageTitle: 'damageAdjustmentEdit',
          breadcrumb: [
            { text: 'stores', active: true },
            { text: 'damageAdjustment', active: true },
          ],
          permission: 'editDamageAdjustments',
        },
      },
    ],
  },
  {
    path: '/addition-order',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: 'new',
        name: 'addition-order-new',
        component: () => import('@/pages/Stores/additionOrder/Edit.vue'),
        meta: {
          pageTitle: 'newAdditionOrder',
          breadcrumb: [{ text: 'stores', active: true }, { text: 'additionOrder', active: true }],
          permission: 'viewAdditionOrders',
        },
      },
      {
        path: 'edit/:id',
        name: 'addition-order-edit',
        props: true,
        component: () => import('@/pages/Stores/additionOrder/Edit.vue'),
        meta: {
          pageTitle: 'editAdditionOrder',
          breadcrumb: [{ text: 'stores', active: true }, { text: 'additionOrder', active: true }],
          permission: 'editAdditionOrders',
        },
      },
    ],
  },
  {
    path: '/storage-transfer',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'storage-transfer',
        component: () => import('@/pages/Stores/storageTransfer/List.vue'),
        meta: {
          pageTitle: 'storageTransfer',
          breadcrumb: [{ text: 'stores', active: true }],
          permission: 'viewStorageTransfer',
        },
      },
      {
        path: 'new',
        name: 'storage-transfer-new',
        component: () => import('@/pages/Stores/storageTransfer/Edit.vue'),
        meta: {
          pageTitle: 'storageTransferNew',
          breadcrumb: [{ text: 'stores', active: true }, { text: 'storageTransfer', active: true }],
          permission: 'addStorageTransfer',
        },
      },
      {
        path: 'edit/:id',
        name: 'storage-transfer-edit',
        props: true,
        component: () => import('@/pages/Stores/storageTransfer/Edit.vue'),
        meta: {
          pageTitle: 'storageTransferEdit',
          breadcrumb: [{ text: 'stores', active: true }, { text: 'storageTransfer', active: true }],
          permission: 'editStorageTransfer',
        },
      },
    ],
  },
]
