export default [
  {
    path: '/employees-dashboard',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
        {
            path: '',
            name: 'employeesDashboard',
            component: () => import('@/pages/Statistics/employeesDashboard.vue'),
            meta: {
                pageTitle: 'employeesDashboard',
                breadcrumb: [{
                    text: 'statistics',
                    active: true
                }],
                permission: 'viewEmployeesDashboard',
            },
        },
    ]
  }
]
