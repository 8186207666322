export default [
{
    path: '/collection-transactions',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'collection-transactions',
        component: () => import('@/pages/Transactions/collectionTransactions/List.vue'),
        meta: {
          pageTitle: 'Collection Transaction',
          breadcrumb: [{ text: 'assets', active: true }],
          permission: 'viewCollectionTransactions',
        },
      },
      {
        path: 'new',
        name: 'collection-transactions-new',
        component: () => import('@/pages/Transactions/collectionTransactions/Edit.vue'),
        meta: {
          pageTitle: 'collection-transactions-new',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'Collection Transaction', active: true },
          ],
          permission: 'addCollectionTransactions',
        },
      },
      {
        path: 'edit/:id',
        name: 'collection-transactions-edit',
        props: true,
        component: () => import('@/pages/Transactions/collectionTransactions/Edit.vue'),
        meta: {
          pageTitle: 'collection-transactions-edit',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'Collection Transaction', active: true },
          ],
          permission: 'editCollectionTransactions',
        },
      },
    ],
  },
  {
    path: '/payment-transactions',
    name: 'payment-transactions',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'payment-transactions',
        component: () => import('@/pages/Transactions/collectionTransactions/List.vue'),
        meta: {
          pageTitle: 'paymentTransaction',
          breadcrumb: [{
            text: 'assets',
            active: true
          }],
          permission: 'viewPaymentTransactions',
        },
      },
      {
        path: 'new',
        name: 'payment-transactions-new',
        component: () => import('@/pages/Transactions/collectionTransactions/Edit.vue'),
        meta: {
          pageTitle: 'payment-transactions-new',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'paymentTransaction', active: true },
          ],
          permission: 'addPaymentTransactions',
        },
      },
      {
        path: 'edit/:id',
        name: 'payment-transactions-edit',
        props: true,
        component: () => import('@/pages/Transactions/collectionTransactions/Edit.vue'),
        meta: {
          pageTitle: 'payment-transactions-edit',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'paymentTransaction', active: true },
          ],
          permission: 'editPaymentTransactions',
        },
      },
    ],
  },
  {
    path: '/studentDiscounts',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
          path: '',
          name: 'studentDiscounts',
          component: () => import('@/pages/Transactions/studentDiscounts/List.vue'),
          meta: {
              pageTitle: 'applyDiscounts',
              breadcrumb: [{
                  text: 'procedures',
                  active: true
              }],
              permission: 'viewStudentDiscounts',
          },
      },
      {
          path: 'new',
          name: 'studentDiscounts-new',
          props: true,
          component: () => import('@/pages/Transactions/studentDiscounts/Edit.vue'),
          meta: {
              pageTitle: 'studentDiscounts-new',
              breadcrumb: [
                  { text: 'procedures', active: true },
                  { text: 'studentDiscounts', active: true },
              ],
              permission: 'addStudentDiscounts',
          },
      },
      {
          path: 'edit/:id',
          name: 'studentDiscounts-edit',
          props: true,
          component: () => import('@/pages/Transactions/studentDiscounts/Edit.vue'),
          meta: {
              pageTitle: 'studentDiscounts-edit',
              breadcrumb: [
                  { text: 'procedures', active: true },
                  { text: 'studentDiscounts', active: true },
              ],
              permission: 'editStudentDiscounts',
          },
      },
    ],
  },

  {
    path: '/income-transactions',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'income-transactions',
        component: () => import('@/pages/Transactions/incomeTransaction/List.vue'),
        meta: {
          pageTitle: 'incomeTransaction',
          breadcrumb: [{ text: 'assets', active: true }],
          permission: 'viewIncomeTransactions',
        },
      },
      {
        path: 'new',
        name: 'income-transactions-new',
        component: () => import('@/pages/Transactions/incomeTransaction/Edit.vue'),
        meta: {
          pageTitle: 'income-transactions-new',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'incomeTransaction', active: true },
          ],
          permission: 'addIncomeTransactions',
        },
      },
      {
        path: 'edit/:id',
        name: 'income-transactions-edit',
        props: true,
        component: () => import('@/pages/Transactions/incomeTransaction/Edit.vue'),
        meta: {
          pageTitle: 'income-transactions-edit',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'incomeTransaction', active: true },
          ],
          permission: 'editIncomeTransactions',
        },
      },
    ],
  },
  {
    path: '/expense-transactions',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'expense-transactions',
        component: () => import('@/pages/Transactions/incomeTransaction/List.vue'),
        meta: {
          pageTitle: 'expenseTransaction',
          breadcrumb: [{ text: 'assets', active: true }],
          permission: 'viewExpenseTransactions',
        },
      },
      {
        path: 'new',
        name: 'expense-transactions-new',
        component: () => import('@/pages/Transactions/incomeTransaction/Edit.vue'),
        meta: {
          pageTitle: 'expense-transactions-new',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'expenseTransaction', active: true },
          ],
          permission: 'addExpenseTransactions',
        },
      },
      {
        path: 'edit/:id',
        name: 'expense-transactions-edit',
        props: true,
        component: () => import('@/pages/Transactions/incomeTransaction/Edit.vue'),
        meta: {
          pageTitle: 'expense-transactions-edit',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'expenseTransaction', active: true },
          ],
          permission: 'editExpenseTransactions',
        },
      },
    ],
  },
  {
    path: '/bankDeposit-transactions',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'bankDeposit-transactions',
        component: () => import('@/pages/Transactions/BankDeposit/List.vue'),
        meta: {
          pageTitle: 'bankDeposit',
          breadcrumb: [{ text: 'assets', active: true }],
          permission: 'bankDeposit',
        },
      },
      {
        path: 'new',
        name: 'bankDeposit-transactions-new',
        component: () => import('@/pages/Transactions/BankDeposit/Edit.vue'),
        meta: {
          pageTitle: 'bankDeposit-transactions-new',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'bankDeposit', active: true },
          ],
          permission: 'bankDeposit',
        },
      },
      {
        path: 'edit/:id',
        name: 'bankDeposit-transactions-edit',
        props: true,
        component: () => import('@/pages/Transactions/BankDeposit/Edit.vue'),
        meta: {
          pageTitle: 'bankDeposit-transactions-edit',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'bankDeposit', active: true },
          ],
          permission: 'bankDeposit',
        },
      },
    ],
  },
  {
    path: '/financialCustody',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'financialCustody',
        component: () => import('@/pages/Transactions/FinancialCustody/List.vue'),
        meta: {
          pageTitle: 'financialCustody',
          breadcrumb: [{ text: 'assets', active: true }],
          permission: 'financialCustody',
        },
      },
      {
        path: 'new',
        name: 'financialCustody-new',
        component: () => import('@/pages/Transactions/FinancialCustody/Edit.vue'),
        meta: {
          pageTitle: 'financialCustody-new',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'financialCustody', active: true },
          ],
          permission: 'financialCustody',
        },
      },
      {
        path: 'edit/:id',
        name: 'financialCustody-edit',
        props: true,
        component: () => import('@/pages/Transactions/FinancialCustody/Edit.vue'),
        meta: {
          pageTitle: 'financialCustody-edit',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'financialCustody', active: true },
          ],
          permission: 'financialCustody',
        },
      },
    ],
  },
  {
    path: '/custodySettlement',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'custodySettlement',
        component: () => import('@/pages/Transactions/FinancialCustodySettlement/List.vue'),
        meta: {
          pageTitle: 'custodySettlement',
          breadcrumb: [{ text: 'assets', active: true }],
          permission: 'custodySettlement',
        },
      },
      {
        path: 'new',
        name: 'custodySettlement-new',
        component: () => import('@/pages/Transactions/FinancialCustodySettlement/Edit.vue'),
        meta: {
          pageTitle: 'custodySettlement-new',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'custodySettlement', active: true },
          ],
          permission: 'custodySettlement',
        },
      },
      {
        path: 'edit/:id',
        name: 'custodySettlement-edit',
        props: true,
        component: () => import('@/pages/Transactions/FinancialCustodySettlement/Edit.vue'),
        meta: {
          pageTitle: 'custodySettlement-edit',
          breadcrumb: [
            { text: 'assets', active: true },
            { text: 'custodySettlement', active: true },
          ],
          permission: 'custodySettlement',
        },
      },
    ],
  },
]
