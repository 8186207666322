var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label":_vm.$t(_vm.$attrs['label-text'])}},[_c('ValidationProvider',{ref:"fieldValidator",attrs:{"rules":_vm.rules || '',"vid":_vm.$attrs.name || '',"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"q-mb-md"},[_c('v-date-picker',{attrs:{"locale":_vm.isRight ? 'ar-EG' : 'en-us',"right":_vm.isRight,"value":_vm.inputValue},on:{"context":_vm.onContext,"input":function (v) {
                _vm.onContext({ selectedYMD: v });
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var inputValue = ref.inputValue;
              var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control rounded",attrs:{"placeholder":"YYYY-MM-DD"},domProps:{"value":inputValue}},inputEvents))]}}],null,true)}),_c('small',{staticClass:"text-danger",class:{ 'd-none': !_vm.$attrs['label-text'] }},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }