export default [
  {
    path: '/expense',
    name: 'expense',
    component: () => import('@/pages/PublicCodes/Expenses/List.vue'),
    meta: {
      pageTitle: 'expense',
      breadcrumb: [{ text: 'publicCodes', active: true }]
    },
  },
  {
    path: '/income',
    name: 'income',
    component: () => import('@/pages/PublicCodes/Incomes/List.vue'),
    meta: {
      pageTitle: 'income',
      breadcrumb: [{ text: 'publicCodes', active: true }],
      permission: 'viewIncomes'
    },
  },
  {
    path: '/banks',
    name: 'banks',
    component: () => import('@/pages/PublicCodes/banks/List.vue'),
    meta: {
      pageTitle: 'banks',
      breadcrumb: [{ text: 'publicCodes', active: true }],
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/pages/PublicCodes/Services/List.vue'),
    meta: {
      pageTitle: 'services',
      breadcrumb: [{ text: 'publicCodes', active: true }],
    },
  },
  {
    path: '/discounts',
    name: 'discounts',
    component: () => import('@/pages/PublicCodes/discount/List.vue'),
    meta: {
      pageTitle: 'discountTypes',
      breadcrumb: [{ text: 'publicCodes', active: true }],
    },
  },
  {
    path: '/payment-methods',
    name: 'payment-methods',
    component: () => import('@/pages/PublicCodes/Paymentmethods/List.vue'),
    meta: {
      pageTitle: 'PaymentMethods',
      breadcrumb: [{ text: 'publicCodes', active: true }],
      permission: 'viewPaymentMethods',
    },
  },
  {
    path: '/safes',
    name: 'safes',
    component: () => import('@/pages/PublicCodes/Safes/List.vue'),
    meta: {
      pageTitle: 'safes',
      breadcrumb: [{ text: 'publicCodes', active: true }],
    },
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('@/pages/PublicCodes/Suppliers/List.vue'),
    meta: {
      pageTitle: 'suppliers',
      breadcrumb: [{ text: 'publicCodes', active: true }],
    },
  },
  {
    path: '/areas',
    name: 'areas',
    component: () => import('@/pages/PublicCodes/Areas/List.vue'),
    meta: {
      pageTitle: 'areas',
      breadcrumb: [{ text: 'publicCodes', active: true }],
    },
  },
  {
    path: '/drivers',
    name: 'drivers',
    component: () => import('@/pages/PublicCodes/Drivers/List.vue'),
    meta: {
      pageTitle: 'drivers',
      breadcrumb: [{ text: 'publicCodes', active: true }],
    },
  },
  {
    path: '/buses',
    name: 'buses',
    component: () => import('@/pages/PublicCodes/Buses/List.vue'),
    meta: {
      pageTitle: 'buses',
      breadcrumb: [{ text: 'publicCodes', active: true }],
    },
  },
  {
    path: '/serviceCategories',
    name: 'serviceCategories',
    component: () => import('@/pages/PublicCodes/ServiceCategories/List.vue'),
    meta: {
      pageTitle: 'serviceCategories',
      breadcrumb: [{ text: 'publicCodes', active: true }],
    },
  },
];
