export default [
  {
    path: '/employees',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'employees-list',
        component: () => import('@/pages/HumanResources/Employees/List.vue'),
        meta: {
          pageTitle: 'employees',
          breadcrumb: [{ text: 'humanResources', active: true }],
        },
      },
      {
        path: 'new',
        name: 'employee-new',
        component: () => import('@/pages/HumanResources/Employees/Edit.vue'),
        meta: {
          pageTitle: 'employee-new',
          breadcrumb: [
            { text: 'humanResources', active: true },
            { text: 'employees', active: true }
          ],
        },
      },
      {
        path: 'edit/:id',
        name: 'employee-edit',
        props: true,
        component: () => import('@/pages/HumanResources/Employees/Edit.vue'),
        meta: {
          pageTitle: 'employee-edit',
          breadcrumb: [
            { text: 'humanResources', active: true },
            { text: 'employees', active: true }
          ],
        },
      },
    ],
  },
  {
    path: '/marital-status',
    name: 'marital-status',
    component: () => import('@/pages/HumanResources/MaritalStatus/List.vue'),
    meta: {
      pageTitle: 'maritalStatus',
      breadcrumb: [{ text: 'humanResources', active: true }],
    },
  },
  {
    path: '/salary-items',
    name: 'salary-items',
    component: () => import('@/pages/HumanResources/SalaryItems/List.vue'),
    meta: {
      pageTitle: 'salaryItems',
      breadcrumb: [{ text: 'humanResources', active: true }],
    },
  },
  {
    path: '/qualification',
    name: 'qualification',
    component: () => import('@/pages/HumanResources/Qualification/List.vue'),
    meta: {
      pageTitle: 'qualification',
      breadcrumb: [{ text: 'humanResources', active: true }],
    },
  },
  {
    path: '/sections',
    name: 'sections',
    component: () => import('@/pages/HumanResources/Sections/List.vue'),
    meta: {
      pageTitle: 'sections',
      breadcrumb: [{ text: 'humanResources', active: true }],
    },
  },
  {
    path: '/jop-title',
    name: 'jop-title',
    component: () => import('@/pages/HumanResources/JopTitle/List.vue'),
    meta: {
      pageTitle: 'jopTitle',
      breadcrumb: [{ text: 'humanResources', active: true }],
    },
  },
  {
    path: '/mangement',
    name: 'mangement',
    component: () => import('@/pages/HumanResources/Mangement/List.vue'),
    meta: {
      pageTitle: 'mangement',
      breadcrumb: [{ text: 'humanResources', active: true }],
    },
  },
  {
    path: '/religion',
    name: 'religion',
    component: () => import('@/pages/HumanResources/Religion/List.vue'),
    meta: {
      pageTitle: 'religion',
      breadcrumb: [{ text: 'humanResources', active: true }],
    },
  },
  {
    path: '/typeVacations',
    name: 'typeVacations',
    component: () => import('@/pages/HumanResources/TypesVacations/List.vue'),
    meta: {
      pageTitle: 'typeVacations',
      breadcrumb: [{ text: 'humanResources', active: true }],
    },
  },
];
