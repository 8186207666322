import vueDebounce from 'vue-debounce'
import Vue from 'vue';
import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';
// mixin
import './mixin/global';
import '@core/scss/vue/libs/vue-select.scss';

Vue.use(vueDebounce)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
