export default [
  {
    path: '/accessGroups',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'accessGroups-list',
        component: () => import('@/pages/Settings/TeamWork/List.vue'),
        meta: {
          pageTitle: 'teamWork',
          breadcrumb: [{ text: 'settings', active: true }],
        },
      },
      {
        path: 'new',
        name: 'accessGroups-new',
        component: () => import('@/pages/Settings/TeamWork/Edit.vue'),
        meta: {
          pageTitle: 'teamWork-new',
          breadcrumb: [{ text: 'teamWork', active: true }],
        },
      },
      {
        path: 'edit/:id',
        name: 'accessGroups-edit',
        props: true,
        component: () => import('@/pages/Settings/TeamWork/Edit.vue'),
        meta: {
          pageTitle: 'teamWork-edit',
          breadcrumb: [{ text: 'teamWork', active: true }],
        },
      },
    ],
  },
  {
    path: '/branches',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'branches-list',
        component: () => import('@/pages/Settings/Branches/List.vue'),
        meta: {
          pageTitle: 'branches',
          breadcrumb: [{ text: 'settings', active: true }],
          permission: 'viewBranches',
        },
      },
      {
        path: 'new',
        name: 'branch-new',
        component: () => import('@/pages/Settings/Branches/Edit.vue'),
        meta: {
          pageTitle: 'branch-new',
          breadcrumb: [{ text: 'branches', active: true }],
          permission: 'addBranches'
        },
      },
      {
        path: 'edit/:id',
        name: 'branch-edit',
        props: true,
        component: () => import('@/pages/Settings/Branches/Edit.vue'),
        meta: {
          pageTitle: 'branch-edit',
          breadcrumb: [{ text: 'branches', active: true }],
          permission: 'editBranches'
        },
      },
    ],
  },
  {
    path: '/users',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@/pages/Settings/Users/List.vue'),
        meta: {
          pageTitle: 'users',
          breadcrumb: [{ text: 'settings', active: true }],
          permission: 'viewUsers',
        },
      },
      {
        path: 'new',
        name: 'new-users',
        component: () => import('@/pages/Settings/Users/Edit.vue'),
        meta: {
          pageTitle: 'newUser',
          breadcrumb: [
            { text: 'Settings', active: true },
            { text: 'users', active: true },
          ],
          permission: 'addUsers',
        },
      },
      {
        path: 'edit/:id',
        name: 'edit-users',
        props: true,
        component: () => import('@/pages/Settings/Users/Edit.vue'),
        meta: {
          pageTitle: 'editUser',
          breadcrumb: [
            { text: 'Settings', active: true },
            { text: 'users', active: true },
          ],
          permission: 'editUsers',
        },
      },
    ],
  },
  {
    path: '/messages-settings',
    name: 'messages-settings',
    component: () => import('@/pages/Settings/Messages/Edit.vue'),
    meta: {
      pageTitle: 'messages-settings',
      breadcrumb: [{ text: 'settings', active: true }],
    },
  },
];
