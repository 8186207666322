export default
[
  {
    path: '/units',
    name: 'units',
    component: () => import('@/pages/Items/Units/List.vue'),
    meta: {
      pageTitle: 'units',
      breadcrumb: [{ text: 'items', active: true }],
      permission: 'viewUnits',
    },
  },
  {
    path: '/items-category',
    name: 'items-category',
    component: () => import('@/pages/Items/ItemCategory/List.vue'),
    meta: {
      pageTitle: 'itemsCategories',
      breadcrumb: [{ text: 'items', active: true }],
      permission: 'viewItemCategories',
    },
  },
  {
    path: '/Items',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: '',
        name: 'items',
        component: () => import('@/pages/Items/ItemList/List.vue'),
        meta: {
          pageTitle: 'itemsList',
          breadcrumb: [{ text: 'items', active: true }],
          permission: 'viewItems',
        },
      },
      {
        path: 'new',
        name: 'add-item',
        component: () => import('@/pages/Items/ItemList/Edit.vue'),
        meta: {
          pageTitle: 'addNewItem',
          breadcrumb: [
            { text: 'items', active: true },
            { text: 'itemsList', active: true },
          ],
          permission: 'addItems',
        },
      },
      {
        path: 'edit/:id',
        name: 'edit-item',
        props: true,
        component: () => import('@/pages/Items/ItemList/Edit.vue'),
        meta: {
          pageTitle: 'editItem',
          breadcrumb: [
            { text: 'items', active: true },
            { text: 'itemsList', active: true },
          ],
          permission: 'editItems',
        },
      },
    ],
  },
]
