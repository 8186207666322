export default [
  {
    path: '/warehouses',
    name: 'warehouses',
    component: () => import('@/pages/Purchases/Warehouses/List.vue'),
    meta: {
      pageTitle: 'warehouses',
      breadcrumb: [{ text: 'purchases', active: true }]
    },
  },
  {
    path: '/purch-invoice-list',
    name: 'purchInvoice-list',
    component: () => import('@/pages/Purchases/PurchaseInvoice/List.vue'),
    meta: {
      pageTitle: 'previewPurchases',
      breadcrumb: [{ text: 'purchases', active: true }],
      permission: 'viewPurchTransactions',
    },
  },
  {
    path: '/purchOrder',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: 'new',
        name: 'purchOrder',
        component: () => import('@/pages/Purchases/PurchaseOrder/Edit.vue'),
        meta: {
          pageTitle: 'newPurchaseOrder',
          breadcrumb: [{ text: 'purchases', active: true }],
          permission: 'addPurchOrders',
        },
      },
      {
        path: 'edit/:id',
        name: 'purchOrder-edit',
        props: true,
        component: () => import('@/pages/Purchases/PurchaseOrder/Edit.vue'),
        meta: {
          pageTitle: 'editPurchaseOrder',
          breadcrumb: [{ text: 'purchases', active: true }],
          permission: 'editPurchOrders',
        },
      },
    ],
  },
  {
    path: '/purch-invoice',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: 'new',
        name: 'purchInvoice',
        component: () => import('@/pages/Purchases/PurchaseInvoice/Edit.vue'),
        meta: {
          pageTitle: 'newPurchaseInvoice',
          breadcrumb: [{ text: 'purchases', active: true }],
          permission: 'addPurchTransactions',
        },
      },
      {
        path: 'edit/:id',
        name: 'purchInvoice-edit',
        props: true,
        component: () => import('@/pages/Purchases/PurchaseInvoice/Edit.vue'),
        meta: {
          pageTitle: 'editPurchaseInvoice',
          breadcrumb: [{ text: 'purchases', active: true }],
          permission: 'editPurchTransactions',
        },
      },
    ],
  },
  {
    path: '/return-purch',
    component: () => import('@/layouts/navigationIndex.vue'),
    children: [
      {
        path: 'new',
        name: 'return-purch-new',
        component: () => import('@/pages/Purchases/ReturnPurchase/Edit.vue'),
        meta: {
          pageTitle: 'newPurchaseReturn',
          breadcrumb: [{ text: 'returnPurch', active: true }],
          permission: 'addReturnPurchTransactions',
        },
      },
      {
        path: 'edit/:id',
        name: 'return-purch-edit',
        props: true,
        component: () => import('@/pages/Purchases/ReturnPurchase/Edit.vue'),
        meta: {
          pageTitle: 'editPurchaseReturn',
          breadcrumb: [{ text: 'returnPurch', active: true }],
          permission: 'editReturnPurchTransactions',
        },
      },
    ],
  },
]
