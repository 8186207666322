import Vue from 'vue'
import VueIntro from 'vue-introjs';

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import {
  BootstrapVue,
  BootstrapVueIcons,
  ToastPlugin,
  ModalPlugin,
} from 'bootstrap-vue';

import VueHtmlToPaper from 'vue-html-to-paper';
import Print from 'vue-printjs';
import VCalendar from 'v-calendar';
import VueCompositionAPI from '@vue/composition-api';
import moment from 'moment';
import VueFullscreen from 'vue-fullscreen';
import CKEditor from 'ckeditor4-vue';
import '@/directives/index';
import '@/filters/index';
import VueQRCodeComponent from 'vue-qrcode-component';
import VueExcelXlsx from 'vue-excel-xlsx';
import Vue3SimpleHtml2pdf from 'vue3-simple-html2pdf';
import { jsPDF } from 'jspdf';
import VueHtml2Canvas from 'vue-html2canvas';
import VueAutosuggest from 'vue-autosuggest';
import * as VueGoogleMaps from 'vue2-google-maps';
// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@validations';
import '@/libs/sweet-alerts';
import 'intro.js/introjs.css';
// user define components
import GForm from './components/form/index.vue';
import GField from './components/form/inputs/field.vue';
import GPicker from './components/form/inputs/datePicker.vue';
import GDateTimePicker from './components/form/inputs/dateTimePicker.vue';
// import hijriDate from './components/HijriDatePicker.vue';

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    // 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    `${window.location.origin}/print-style.css`,
  ],
  // timeout: 1000, // default timeout before the print window appears
  autoClose: true,
};
// BSV Plugin Registration

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBrGrVHgqpFKB-Ti0l6_qexwwjQ1IoemHY',
  }
})
Vue.use(Print);
Vue.use(VCalendar, {
  componentPrefix: 'v', // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(VueHtmlToPaper, options);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(jsPDF);
Vue.use(VueIntro);
Vue.use(VueAutosuggest);
Vue.use(VueHtml2Canvas);
Vue.use(CKEditor);
Vue.use(VueExcelXlsx);
Vue.use(Vue3SimpleHtml2pdf);
// Composition API
Vue.use(VueCompositionAPI);
// Vue.use(VueQRCodeComponent)
Vue.component('qr-code', VueQRCodeComponent);
// fullscreen
Vue.use(VueFullscreen);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;
Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm');
  }
  return value;
});

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('GForm', GForm)
Vue.component('GField', GField);
// Vue.component('hijriDate', hijriDate);
Vue.component('GPicker', GPicker);
Vue.component('GDateTimePicker', GDateTimePicker);
Vue.component('v-select', vSelect)
